import { graphql, useStaticQuery } from "gatsby";
import { CareerIndexDifferencesQuery } from "./query.generated";

export type CMSDifferencesData = CareerIndexDifferencesQuery["cms"]["site"];

export function useStaticDifferencesQuery(): CMSDifferencesData {
  const data = useStaticQuery<CareerIndexDifferencesQuery>(
    graphql`
      query CareerIndexDifferences {
        cms {
          site(hostname: "www.italymondo.com") {
            careerIndexPage: pages(
              contentType: "italymondo_about.IMCareerIndexPage"
            ) {
              ... on CMS_IMCareerIndexPage {
                italyPerks {
                  perk {
                    description
                    icon {
                      url
                    }
                    title
                  }
                }
                perksDescription
                perksSectionName
                perksTitle
                usaPerks {
                  perk {
                    description
                    icon {
                      url
                    }
                    title
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
