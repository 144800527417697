import { graphql, useStaticQuery } from "gatsby";
import { CareerIndexOurTeamQuery } from "./query.generated";

export type CMSOurTeamData = CareerIndexOurTeamQuery["cms"]["site"];

export function useStaticOurTeamQuery(): CMSOurTeamData {
  const data = useStaticQuery<CareerIndexOurTeamQuery>(
    graphql`
      query CareerIndexOurTeam {
        cms {
          site(hostname: "www.italymondo.com") {
            careerIndexPage: pages(
              contentType: "italymondo_about.IMCareerIndexPage"
            ) {
              ... on CMS_IMCareerIndexPage {
                teamDescription
                teamMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              width: 650
                              height: 450
                            )
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                teamSectionName
                teamTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
