import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO } from "../../components";
import {
  CareerPaths,
  Differences,
  Hero,
  Introduction,
  OurProcess,
  OurTeam,
  TransitionalCTA,
} from "../../sections/CareerIndex";
import { CareerIndexQuery } from "./index.generated";

type PpCareerIndexPage = {
  data: CareerIndexQuery;
  location?: {
    pathname?: string;
  };
};

export default function CareerIndexPage({
  data: {
    cms: { site },
  },
  location,
}: PpCareerIndexPage): React.ReactElement {
  const alertBarData = site.careerIndexPage[0].alertBar?.message
    ? {
        link: {
          text: site.careerIndexPage[0].alertBar?.anchorText || "",
          url: getPathname(site.careerIndexPage[0].alertBar?.page.url) || "",
        },
        text: site.careerIndexPage[0].alertBar?.message || "",
      }
    : undefined;

  return (
    <Layout
      alertBar={alertBarData}
      showProgressBar={site.careerIndexPage[0].progressBar}
    >
      <SEO
        description={site.careerIndexPage[0]?.listingSummary || ""}
        image={site.careerIndexPage[0]?.listingImage?.url}
        location={location}
        title={site.careerIndexPage[0]?.listingTitle || ""}
      />
      <Hero />
      <Introduction />
      <Differences />
      <OurTeam />
      <OurProcess />
      <CareerPaths />
      <TransitionalCTA />
    </Layout>
  );
}

export const query = graphql`
  query CareerIndex {
    cms {
      site(hostname: "www.italymondo.com") {
        careerIndexPage: pages(
          contentType: "italymondo_about.IMCareerIndexPage"
        ) {
          ... on CMS_IMCareerIndexPage {
            alertBar {
              anchorText
              message
              page {
                url
              }
            }
            listingImage {
              url
            }
            listingSummary
            listingTitle
            progressBar
          }
        }
      }
    }
  }
`;
