import React from "react";
import { PageList } from "../../../components";
import { useStaticCareerPathsQuery } from "./query";

export function CareerPaths(): React.ReactElement | null {
  const data = useStaticCareerPathsQuery();

  if (!data?.careerIndexPage[0]) {
    return null;
  }

  return (
    <PageList
      listData={data?.careerIndexPage[0].careerpages || []}
      sectionName={data?.careerIndexPage[0].careerPathsSectionName || ""}
      title={data?.careerIndexPage[0].careerPathsTitle || ""}
    />
  );
}
