import { graphql, useStaticQuery } from "gatsby";
import { HeroCareerIndexQuery } from "./query.generated";

export type CMSHeroData = HeroCareerIndexQuery["cms"]["site"];

export function useStaticHeroQuery(): CMSHeroData {
  const data = useStaticQuery<HeroCareerIndexQuery>(
    graphql`
      query HeroCareerIndex {
        cms {
          site(hostname: "www.italymondo.com") {
            careerIndexPage: pages(
              contentType: "italymondo_about.IMCareerIndexPage"
            ) {
              ... on CMS_IMCareerIndexPage {
                __typename
                heroDescription
                heroHeadline
                heroImage {
                  alt
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                heroPrimaryButton {
                  page {
                    url
                  }
                  title
                }
                heroSecondaryButton {
                  page {
                    url
                  }
                  title
                }
                heroType
                heroVerticalText {
                  name
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
