import React from "react";
import { SectionContainer, SectionHeading } from "@italymondo/core-ui";
import { Stack } from "@mui/material";
import { useStaticOurTeamQuery } from "./query";
import { StyledMedia } from "./styles";

export function OurTeam(): React.ReactElement | null {
  const data = useStaticOurTeamQuery();

  if (!data?.careerIndexPage[0]) {
    return null;
  }

  return (
    <SectionContainer>
      <Stack alignItems="center" spacing={2}>
        <SectionHeading
          description={data.careerIndexPage[0].teamDescription}
          sectionName={data.careerIndexPage[0].teamSectionName || ""}
          textAlign="center"
          title={data.careerIndexPage[0].teamTitle}
        />
        <StyledMedia media={data.careerIndexPage[0].teamMedia[0]} />
      </Stack>
    </SectionContainer>
  );
}
