import React from "react";
import { Hero as HeroComponent } from "@italymondo/core-ui";
import { formatData } from "./config";
import { useStaticHeroQuery } from "./query";

export function Hero(): React.ReactElement | null {
  const data = useStaticHeroQuery();

  if (!data?.careerIndexPage[0]) {
    return null;
  }

  const heroData = formatData(data);

  return <HeroComponent {...heroData} />;
}
