import { graphql, useStaticQuery } from "gatsby";
import { CareerIndexIntroductionQuery } from "./query.generated";

export type CMSIntroductionData = CareerIndexIntroductionQuery["cms"];

export function useStaticIntroductionQuery(): CMSIntroductionData {
  const data = useStaticQuery<CareerIndexIntroductionQuery>(
    graphql`
      query CareerIndexIntroduction {
        cms {
          site(hostname: "www.italymondo.com") {
            careerIndexPage: pages(
              contentType: "italymondo_about.IMCareerIndexPage"
            ) {
              ... on CMS_IMCareerIndexPage {
                introMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 900)
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                introMediaPlacement
                introParagraph
                introShadowHeader
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
