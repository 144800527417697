import { graphql, useStaticQuery } from "gatsby";
import { CareerIndexPathsQuery } from "./query.generated";

export type CMSPathsData = CareerIndexPathsQuery["cms"]["site"];

export function useStaticCareerPathsQuery(): CMSPathsData {
  const data = useStaticQuery<CareerIndexPathsQuery>(
    graphql`
      query CareerIndexPaths {
        cms {
          site(hostname: "www.italymondo.com") {
            careerIndexPage: pages(
              contentType: "italymondo_about.IMCareerIndexPage"
            ) {
              ... on CMS_IMCareerIndexPage {
                careerPathsSectionName
                careerPathsTitle
                careerpages {
                  listingLinkText
                  listingTitle
                  location
                  type
                  url
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
