import { graphql, useStaticQuery } from "gatsby";
import { CareerIndexTransitionalCTAQuery } from "./query.generated";

export type CMSTransitionalCTAData =
  CareerIndexTransitionalCTAQuery["cms"]["site"];

export function useStaticTransitionalCTAQuery(): CMSTransitionalCTAData {
  const data = useStaticQuery<CareerIndexTransitionalCTAQuery>(
    graphql`
      query CareerIndexTransitionalCTA {
        cms {
          site(hostname: "www.italymondo.com") {
            careerIndexPage: pages(
              contentType: "italymondo_about.IMCareerIndexPage"
            ) {
              ... on CMS_IMCareerIndexPage {
                transitionalCta {
                  action {
                    ... on CMS_CollectEmailBlock {
                      __typename
                      collectEmail
                    }
                    ... on CMS_LinkPageBlock {
                      __typename
                      page {
                        url
                      }
                    }
                    ... on CMS_ShowPopupBlock {
                      __typename
                      description
                      title
                    }
                  }
                  buttonText
                  description
                  shadowImage {
                    url
                  }
                  title
                }
                url
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
