import React from "react";
import { TransitionalCTA as TransitionalCTAComponent } from "@italymondo/core-ui";
import { useStaticTransitionalCTAQuery } from "./query";

export function TransitionalCTA(): React.ReactElement | null {
  const data = useStaticTransitionalCTAQuery();

  if (!data?.careerIndexPage[0].transitionalCta) {
    return null;
  }

  const careerIndexPageUrl = data.careerIndexPage[0].url || "/";

  return (
    <TransitionalCTAComponent
      action={{
        __typename:
          data.careerIndexPage[0].transitionalCta?.action[0]?.__typename,
        page: {
          url: `${careerIndexPageUrl}apply`,
        },
      }}
      buttonText={data.careerIndexPage[0].transitionalCta?.buttonText}
      description={data.careerIndexPage[0].transitionalCta?.description || ""}
      shadowImage={
        data.careerIndexPage[0].transitionalCta?.shadowImage?.url || ""
      }
      title={data.careerIndexPage[0].transitionalCta?.title || ""}
    />
  );
}
