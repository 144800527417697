import { graphql, useStaticQuery } from "gatsby";
import { CareerIndexOurProcessQuery } from "./query.generated";

export type CMSOurProcessData = CareerIndexOurProcessQuery["cms"]["site"];

export function useStaticOurProcessQuery(): CMSOurProcessData {
  const data = useStaticQuery<CareerIndexOurProcessQuery>(
    graphql`
      query CareerIndexOurProcess {
        cms {
          site(hostname: "www.italymondo.com") {
            careerIndexPage: pages(
              contentType: "italymondo_about.IMCareerIndexPage"
            ) {
              ... on CMS_IMCareerIndexPage {
                process {
                  cta {
                    page {
                      url
                    }
                    title
                  }
                  description
                  steps {
                    description
                    name
                    step
                  }
                  title
                }
                processMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              width: 650
                              height: 550
                            )
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                processMediaPlacement
                processSectionName
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
