import React from "react";
import { Differences as DifferencesComponent } from "@italymondo/core-ui";
import { useStaticDifferencesQuery } from "./query";

export function Differences(): React.ReactElement | null {
  const data = useStaticDifferencesQuery();

  if (!data?.careerIndexPage[0]) {
    return null;
  }

  return (
    <DifferencesComponent
      darkBackground
      description={data?.careerIndexPage[0].perksDescription || ""}
      differences={[
        {
          data:
            data?.careerIndexPage[0].usaPerks.map((p) => ({
              ...p.perk,
              indexKey: `usa-${p.perk.title}`,
            })) || [],
          tierName: "USA",
        },
        {
          data:
            data?.careerIndexPage[0].italyPerks.map((p) => ({
              ...p.perk,
              indexKey: `italy-${p.perk.title}`,
            })) || [],
          tierName: "ITALY",
        },
      ]}
      sectionName={data?.careerIndexPage[0].perksSectionName || ""}
      title={data?.careerIndexPage[0].perksTitle || ""}
    />
  );
}
